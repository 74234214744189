$(document).foundation();


// ScrollMagic
var controller = new ScrollMagic.Controller();

// ScrollMagic - Fade In Main Header
TweenLite.to(".slide-bg", 0.3, {
    opacity: "0.6",
    ease: Power2.easeIn
});

//  ScrollMagic - Parallax Backgrounds
$(".slide-wrap").each(function() {
    var bg = $(this).find(".slide-bg");
    var layer = $(this).find(".slide-layer");
    var square = $(this).find(".slide-square");

    var parallax = TweenMax.from(bg, 1, {
        y: '-300px',
        ease: Power0.easeNone
    });

    var parallax2 = TweenMax.from(layer, 1, {
        y: '-400px',
        ease: Power0.easeNone
    });

    var parallax3 = TweenMax.from(square, 1, {
        y: '-500px',
        ease: Power0.easeNone
    });

    var parallaxScene = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1,
        duration: '300%',
    }).setTween(parallax).addTo(controller);

    var parallaxScene2 = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1,
        duration: '300%',
    }).setTween(parallax2).addTo(controller);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 1,
        duration: '220%',
    }).setTween(parallax3).addTo(controller);

});

// ScrollMagic - FadeIn Elements
$('.fade-in').each(function() {
    var tween = TweenMax.from($(this), 0.3, {
        autoAlpha: 0,
        scale: 0.5,
        y: '+=30',
        ease: Linear.easeNone
    });

});


var menu_btn = $("#menu-btn-burger");
var menu_anim = new TimelineLite({
    paused: true,
    reversed: true
});

menu_anim.set("#main-menu", {
        opacity: 1
    })
    .to("#menu-btn-burger", 0.1, {
        css: {
            "border-radius": "0"
        }
    })
    .to("#main-menu", 0.08, {
        className: "+=main-menu-open",
        delay: 0.1
    })
    .to("#main-menu", 0.1, {
        className: "+=main-menu-open-height",
        delay: 0.2
    })
    .to("#main-menu ul", 0.1, {
        css: {
            "opacity": "1"
        }
    });

menu_btn.click(function() {
    menu_anim.reversed() ? menu_anim.restart() : menu_anim.reverse();
    $(this).toggleClass("open");
});
